import React from 'react';
import arrow from '../../assets/img/arrow.svg';
import classes from './Paginator.module.scss';

const Paginator = ({ fetchMore, paginator, setPage }) => {
    return (
        <div className={classes.Pagination}>
            <div>
                {paginator.currentPage} из {paginator.pageCount}
            </div>
            <div className={classes.Buttons}>
                <button className={[classes.Button, classes.Prev].join(' ')} onClick={() => {
                    const { prev } = paginator;

                    fetchMore({
                        variables: {
                            page: prev
                        },
                        updateQuery: (prevResult, { fetchMoreResult }) => {
                            setPage(prev);
                            return fetchMoreResult;
                        }
                    })
                }}>
                    <img src={arrow} alt=""/>
                </button>

                <button className={classes.Button} onClick={() => {
                    const { next } = paginator;

                    fetchMore({
                        variables: {
                            page: next
                        },
                        updateQuery: (prevResult, { fetchMoreResult }) => {
                            setPage(next);
                            return fetchMoreResult;
                        }
                    })
                }}>
                    <img src={arrow} alt=""/>
                </button>
            </div>
        </div>
    )
};

export default Paginator;