import React, { useState } from 'react';
import Page from "../../components/Page/Page";
import Search from "../../components/Search/Search";
import {useQuery} from "@apollo/client";
import { FETCH_COMPANY } from '../../grapgql/gql';
import {useSearch} from "../../context/search";
import {Table, Td, Tr} from "../../components/UI/Table/Table";
import Controls from "../../components/Controls/Controls";

const Company = () => {
    const { searchValue } = useSearch();
    const [page, setPage] = useState(1);
    const { loading, data: { getScCompany: company } = {}, fetchMore } = useQuery(FETCH_COMPANY, {
        pollInterval: 1000,
        variables: {
            page: page,
            limit: 20,
            search: searchValue
        }
    });

    return (
        <Page
            title="Список компаний"
            callback={setPage}
            fetchMore={fetchMore}
            loading={loading}
            paginator={!loading && company.paginator}
            controls={
                <>
                    <Search
                        setPage={setPage}
                        placeholder="Поиск: контактное лицо, компания, email, номер телефона"
                    />
                </>
            }>
                <Table value={[
                    {name: "number", label: '№'},
                    {name: "company", label: 'Наименование компании'},
                    {name: "device", label: 'Контактное лицо' },
                    {name: "phone", label: 'Номер телефона'},
                    {name: "email", label: 'Email'},
                    {name: "controls", label: 'Действия'}
                ]}>
                    { company && company.data.map((item, index) => {
                        return (
                            <Tr key={item.id}>
                                <Td>{company.paginator.slNo + index}</Td>
                                <Td>{item.companyname}</Td>
                                <Td>{item.username} </Td>
                                <Td>{item.userphone}</Td>
                                <Td>{item.useremail}</Td>
                                <Td>
                                    <Controls
                                        item={item}
                                        link="/company/"
                                        controls={{
                                            info: true
                                        }}
                                    />
                                </Td>
                            </Tr>
                        )
                    }) }
                </Table>
        </Page>
    )
}

export default Company;

