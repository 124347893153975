import React, { useState } from 'react';
import Controls from "../../components/Controls/Controls";
import Moment from "react-moment";
import Page from "../../components/Page/Page";
import { useQuery } from "@apollo/client";
import { FETCH_ARCHIVE } from "../../grapgql/gql";
import { Table, Td, Tr } from "../../components/UI/Table/Table";
import {useSearch} from "../../context/search";
import Search from "../../components/Search/Search";

const Archive = () => {
    const { searchValue } = useSearch();
    const [page, setPage] = useState(1);

    const { loading, data: { getScRequests: request } = {}, fetchMore } = useQuery(FETCH_ARCHIVE, {
        pollInterval: 1000,
        variables: {
            page: page,
            limit: 20,
            search: searchValue
        }
    });

    return (
        <Page
            title="Архив"
            callback={setPage}
            fetchMore={fetchMore}
            loading={loading}
            paginator={!loading && request.paginator}
            controls={
                <>
                    <Search
                        setPage={setPage}
                        placeholder="Поиск: код, компания, email, номер телефона, серийник, модель"
                    />
                </>
            }
        >
            <Table value={[
                {name: "number", label: '№'},
                {name: "company", label: 'Компания'},
                {name: "device", label: 'Приборы' },
                {name: "phone", label: 'Номер телефона'},
                {name: "createdAt", label: 'Дата регистрации'},
                {name: "endAt", label: 'Дата завершения'},
                {name: "controls", label: 'Действия'}
            ]}>
                { request && request.data.map((item, index) => {
                    return (
                        <Tr key={item.id}>
                            <Td>{item.code}</Td>
                            <Td>{item.companyname} </Td>
                            <Td>
                                {item.devices.map((item, index) => {
                                    return (
                                        <p key={index}>
                                            {item.model} ({item.serial})
                                        </p>
                                    )
                                })}
                            </Td>
                            <Td>{item.userphone}</Td>
                            <Td><Moment date={item.createdAt} format="DD.MM.YYYY, HH:mm"/></Td>
                            <Td><Moment date={item.finishedAt} format="DD.MM.YYYY, HH:mm"/></Td>
                            <Td>
                                <Controls
                                    item={item}
                                    link="/request/"
                                    controls={{
                                        info: true,
                                        delete: true
                                    }}
                                />
                            </Td>
                        </Tr>
                    )
                }) }
            </Table>
        </Page>
    )
};

export default Archive;
