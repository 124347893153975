import React from 'react';
import classes from "./Group.module.scss";


export const Group = ({ children, label, error, size }) => {
    const cls = [classes.Group, size === 'full' ? classes.GroupFull : null];
    return (
        <div className={cls.join(' ')}>
            {label && <label className={classes.Label}>{label}</label> }
            { children }
            {error && <span className={classes.Error}>{error}</span> }
        </div>
    )
}