import React from 'react';
import classes from "../Current/Current.module.scss";
import {Table, Td, Tr} from "../../components/UI/Table/Table";
import Page from "../../components/Page/Page";
import {useParams} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {GET_CURRENT_COMPANY} from "../../grapgql/gql";
import Moment from "react-moment";
import Status from "../../components/Status/Status";
import Controls from "../../components/Controls/Controls";

const CompanyCurrent = () => {
    const { id } = useParams();
    const { data: { getScCompanyInfo: company } = {} } = useQuery(GET_CURRENT_COMPANY, {
        pollInterval: 750,
        variables: {
            companyId: id
        }
    });

    return (
        <Page
            title="Информация о компании"
        >
            {
                company &&
                <>
                    <div className={classes.Info}>
                        <div className={classes.Item}>
                            <span>Компания:</span>
                            <p>{company.companyname}</p>
                        </div>
                        <div className={classes.Item}>
                            <span>Имя: </span>
                            <p>{company.username}</p>
                        </div>

                        <div className={classes.Item}>
                            <span>Номер телефона:</span>
                            <p>{company.userphone}</p>
                        </div>
                        <div className={classes.Item}>
                            <span>Email:</span>
                            <p>{company.useremail}</p>
                        </div>
                        <div className={classes.Item}>
                            <span>Заявок:</span>
                            <p>{company.requests.length}</p>
                        </div>
                    </div>
                    <Table value={[
                        {name: "number", label: '№'},
                        {name: "device", label: 'Приборы' },
                        {name: "createdAt", label: 'Дата регистрации'},
                        {name: "status", label: 'Статус заявки'},
                        {name: "controls", label: 'Действия'}
                    ]}>
                        { company && company.requests.map((item, index) => {
                            return (
                                <Tr key={item.id}>
                                    <Td>{item.code}</Td>
                                    <Td>
                                        {item.devices.map((item, index) => {
                                            return (
                                                <p key={index}>
                                                    {item.model} ({item.serial})
                                                </p>
                                            )
                                        })}
                                    </Td>
                                    <Td><Moment date={item.createdAt} format="DD.MM.YYYY, HH:mm"/></Td>
                                    <Td style={{ width: "200px"}}>
                                        <Status defaultStatus={item.status.label} id={item.id} />
                                    </Td>
                                    <Td style={{textAlign: 'center', width: "125px"}} >
                                        <Controls
                                            item={item}
                                            link="/request/"
                                            controls={{
                                                delete: true,
                                                pdf: true,
                                                info: true
                                            }}
                                        />
                                    </Td>
                                </Tr>
                            )
                        }) }
                    </Table>
                </>
            }
        </Page>
    )
}

export default CompanyCurrent;