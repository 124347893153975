import React from 'react';
import classes from './Search.module.scss';
import searchIcon from '../../assets/img/search.svg';
import { useSearch } from '../../context/search';


const Search = ({ placeholder, pageCount, setPage }) => {
    const { setSearchValue, searchValue } = useSearch();


    return (
        <div className={classes.Search}>
            <img src={searchIcon} alt=""/>
            <input
                type="text"
                name="search"
                value={searchValue}
                placeholder={placeholder}
                onChange={(event) => {
                    setSearchValue(event)
                    console.log(pageCount)
                    setPage(pageCount);
                }}
            />
        </div>
    )
};

export default Search;