import React from 'react';
import classes from './Device.module.scss';
import {Group} from "../UI/Group/Group";
import {Input} from "../UI/Input/Input";
import {Textarea} from "../UI/Textarea/Textarea";
import Select from "react-select";
import {useQuery} from "@apollo/client";
import gql from "graphql-tag";
import CreatableSelect from "react-select/creatable/dist/react-select.esm";


export const Device = ({ devices, index, values, onChange, device, deviceHandler }) => {
    const {servicesLoading, data: {getScServices: services} = {}} = useQuery(FETCH_SERVICES)
    const {deviceSelectLoading, data: {getScDevices: deviceSelect} = {}} = useQuery(FETCH_DEVICE);

    return (
        <div className={classes.Device}>
            { Object.keys(devices).map((item, key) => {
                const controlName = item;
                const field = device[index][item];

                if (field.type === 'text') {
                    return (
                        <Group label={field.label} key={key}>
                            <Input
                                type="text"
                                idx={index}
                                name={controlName}
                                value={values.devices[index].controlName}
                                onChange={onChange}
                            />
                        </Group>
                    )
                } else if (field.type === 'textarea') {
                    return  (
                        <Group label={field.label} size="full" key={key}>
                            <Textarea
                                type="text"
                                idx={index}
                                name={controlName}
                                value={values.devices[index].controlName}
                                onChange={onChange}
                            />
                        </Group>
                    )
                } else if (field.type === 'select') {
                    return (
                        <Group label={field.label} key={key}>
                            <Select
                                isLoading={servicesLoading}
                                options={services && services.map(item => {
                                    return {
                                        label: item.label,
                                        value: item.label,
                                        name: controlName
                                    }
                                })}
                                placeholder="Выберите..."
                                onChange={event => onChange({ target: { value: event.value, name: 'service', dataset: { idx: index.toString() }  }})}
                            />
                        </Group>
                    )
                } else if (field.type === 'create') {
                    return (
                        <Group label="Наименование прибора" key={index}>
                            <CreatableSelect
                                classNamePrefix="select"
                                isClearable
                                isLoading={deviceSelectLoading}
                                placeholder="Выберите или добавьте..."
                                options={deviceSelect && deviceSelect.map((item) => {
                                    return {
                                        value: item.label,
                                        label: item.label
                                    }
                                })}
                                onInputChange={event => event && onChange({ target: {value: event.value, name: 'model', dataset: { idx: index.toString() }}})}
                                onChange={event => event && onChange({ target: {value: event.value, name: 'model', dataset: { idx: index.toString() }}})}
                            />
                        </Group>
                    )
                }
            })}
        </div>
    )
}

const FETCH_SERVICES = gql`
    {
        getScServices {
            id
            label
            value
            sort
        }
    }
`;

const FETCH_DEVICE = gql`
    {
        getScDevices {
            id
            label
        }
    }
`;