import React from 'react';
import Select from "react-select";
import {useDispatch} from "react-redux";
import {useMutation, useQuery} from "@apollo/client";
import {CHANGE_STATUS, FETCH_STATUS} from "../../grapgql/gql";
import { openCertificateModal } from "../../store/reducers/modalReducer";

const Status = ({ defaultStatus, id }) => {
    const dispatch = useDispatch();

    const {statusLoading, data: { getScStatus: status } = {}} = useQuery(FETCH_STATUS);

    const [changeStatus, { loading }] = useMutation(CHANGE_STATUS);

    return (
        <>
            <Select
                isLoading={statusLoading}
                defaultValue={{ label: defaultStatus }}
                options={status}
                placeholder="Выберите..."
                onChange={event => {
                    if (event.value === 'metrologydone') {
                        dispatch(openCertificateModal({ id, value: event.value }))
                    }
                    changeStatus({
                        variables: {
                            id,
                            type: event.type,
                            value: event.value,
                            label: event.label
                        }
                    })
                }}
            />
        </>
    )
}

export default Status;


