import React from 'react';
import classes from './Current.module.scss';
import { useQuery } from '@apollo/client';
import { useParams } from "react-router-dom";
import { FETCH_CURRENT_REQUEST } from '../../grapgql/gql';
import Page from "../../components/Page/Page";
import {Table, Td, Tr} from "../../components/UI/Table/Table";

const Current = () => {
    const { id } = useParams();
    const { data: { getScRequest: request } = {} } = useQuery(FETCH_CURRENT_REQUEST, {
        pollInterval: 1000,
        variables: {
            requestID: id
        }
    });

    return (
        <Page
            title="Информация о заявке"
        >
            {
                request &&
                    <>
                    <div className={classes.Info}>
                        <div className={classes.Item}>
                            <span>ID: { request.code }</span>
                            <p>{request.username}</p>
                        </div>
                        <div className={classes.Item}>
                            <span>Компания:</span>
                            <p>{request.companyname}</p>
                        </div>
                        <div className={classes.Item}>
                            <span>Номер телефона:</span>
                            <p>{request.userphone}</p>
                        </div>
                        <div className={classes.Item}>
                            <span>Email:</span>
                            <p>{request.useremail}</p>
                        </div>
                        <div className={classes.Item}>
                            <span>Устройств:</span>
                            <p>{request.devices.length}</p>
                        </div>
                    </div>
                        <Table value={[
                            {name: "number", label: '№'},
                            {name: "model", label: 'Модель'},
                            {name: "serial", label: 'Серийный номер' },
                            {name: "certificate", label: 'Номер сертификата' },
                            {name: "year", label: 'Год'},
                            {name: "service", label: 'Услуга'},
                            {name: "comment", label: 'Комментарий/Примечание'}
                        ]}>
                            { request && request.devices.map((item, index) => {
                                return (
                                    <Tr key={item.id}>
                                        <Td>{index + 1}</Td>
                                        <Td>{item.model}</Td>
                                        <Td>{item.serial} </Td>
                                        <Td>{item.certificate || '-'} </Td>
                                        <Td>{item.year}</Td>
                                        <Td>{item.service}</Td>
                                        <Td>{item.comment}</Td>
                                    </Tr>
                                )
                            }) }
                        </Table>
                </>
            }
        </Page>
    )
}

export default Current;
