import React from 'react';
import classes from './Table.module.scss';


const Table = (props) => {
    return (
        <table className="ui celled table">
            <Thead>
                { props.value.map((item, index) => {
                    return (
                        <Th key={index} className={[item.classes, item.class].join(' ')} type={item.type}>{item.label}</Th>
                    )
                }) }
            </Thead>
            <Tbody>
                {props.children}
            </Tbody>
        </table>
    )
}

const Thead = (props) => {
    return (
        <thead>
        <tr>
            {props.children}
        </tr>
        </thead>
    )
}

const Tbody = (props) => {
    return (
        <tbody className={classes.Tbody}>
        {props.children}
        </tbody>
    )
}

const Tr = (props) => {
    return (
        <tr className={[classes.Tr, props.method === 'CARD' && classes.TrCard].join(' ')} onClick={props.onClick}>
            { props.children }
        </tr>
    )
}

const Td = (props) => {
    const cls = [classes.Td];

    switch (props.type) {
        case 'image':
            cls.push(classes.TdImage);
            break;
        case 'controls':
            cls.push(classes.TdControls);
            break;
        default:
            break;
    }
    return (
        <td className={cls.join(' ')}>
            { props.children }
        </td>
    )
}

const Th = (props) => {
    let width = '';

    if (props.type === 'company') {
        width = '180px';
    } else if (props.type === 'controls') {
        width = '200px';
    }
    return (
        <th className={classes.Th} style={{width: width }}>
            { props.children }
        </th>
    )
}

export { Table, Thead, Tbody, Tr, Td, Th };
