import React, {useState} from 'react';
import Moment from "react-moment";
import Page from "../../components/Page/Page";
import Controls from "../../components/Controls/Controls";
import { useQuery } from "@apollo/client";
import { FETCH_REQUEST_SKLAD } from '../../grapgql/gql';
import { Table, Td, Tr } from "../../components/UI/Table/Table";
import {useSearch} from "../../context/search";
import Search from "../../components/Search/Search";
import Status from "../../components/Status/Status";


const Sklad = () => {
    const { searchValue } = useSearch();
    const [page, setPage] = useState(1);

    const { loading, data: { getScRequests: request } = {}, fetchMore } = useQuery(FETCH_REQUEST_SKLAD, {
        pollInterval: 1000,
        variables: {
            page: page,
            limit: 20,
            search: searchValue
        }
    });

    return (
        <Page
            title="Склад"
            callback={setPage}
            fetchMore={fetchMore}
            loading={loading}
            paginator={!loading && request.paginator}
            controls={
                <>
                    <Search
                        setPage={setPage}
                        placeholder="Поиск: код, компания, email, номер телефона, серийник, модель"
                    />
                </>
            }
        >
            <Table value={[
                {name: "number", label: '№'},
                {name: "company", label: 'Компания', type: 'company'},
                {name: "device", label: 'Приборы', type: 'device' },
                {name: "createdAt", label: 'Дата регистрации'},
                {name: "phone", label: 'Дата переноса'},
                {name: "status", label: 'Статус заявки', type: 'controls'},
                {name: "controls", label: 'Действия'}
            ]}>
                { request && request.data.map((item, index) => {
                    return (
                        <Tr key={index}>
                            <Td data-label="№">{item.code}</Td>
                            <Td data-label="№">{item.companyname} </Td>
                            <Td data-label="Прибор">
                                { item.devices.map((item, index) => {
                                    return (
                                        <p key={index}>
                                            {item.model} ({item.serial})
                                        </p>
                                    )
                                }) }
                            </Td>
                            <Td data-label="Дата регистрации"><Moment date={item.createdAt} format="DD.MM.YYYY, HH:mm"/></Td>
                            <Td data-label="Дата регистрации"><Moment date={item.finishedAt} format="DD.MM.YYYY, HH:mm"/></Td>
                            <Td style={{ width: "200px"}}>
                                <Status defaultStatus={item.status.label} id={item.id} />
                            </Td>
                            <Td data-label="Кнопки управления">
                                <Controls
                                    item={item}
                                    link="/request/"
                                    controls={{
                                        info: true,
                                        delete: true
                                    }}
                                />
                            </Td>
                        </Tr>
                    )
                }) }
            </Table>
        </Page>
    )
}

export default Sklad;


