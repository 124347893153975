import React, {useContext, useReducer} from 'react';

export const SearchContext = React.createContext();

export const useSearch = () => {
    return useContext(SearchContext);
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'value': return {
            ...state,
            searchValue: action.value.target.value,
        }
        default:
            return state;
    }
}


export const SearchProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, {
        searchValue: ''
    })

    const setSearchValue = (value) => dispatch({ type: 'value', value });

    return (
        <SearchContext.Provider value={{
            searchValue: state.searchValue,
            setSearchValue
        }}>
            { children }
        </SearchContext.Provider>
    )
}