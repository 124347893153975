import React, { useContext } from 'react';
import classes from './Navbar.module.scss';
import logo from '../../assets/img/logo.png';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../../context/auth';

export const Navbar = () => {
    const { user, logout } = useContext(AuthContext);

    if (!user) {
        return false;
    }

    return (
        <div className={classes.Navbar}>
            <NavLink to="/create" className="item">
                <i className="edit icon"/>
                <span>Создание заявки</span>
            </NavLink>
            <NavLink to="/request" className="item">
                <i className="tasks icon"/>
                <span>Активные заявки</span>
            </NavLink>
            <NavLink to="/archive" className="item">
                <i className="archive icon" />
                <span>Архив заявок</span>
            </NavLink>
            <NavLink to="/sklad" className="item">
                <i className="folder icon" />
                <span>Склад приборов</span>
            </NavLink>
            <NavLink to="/company" className="item">
                <i className="address card outline icon" />
                <span>Список компаний</span>
            </NavLink>
            <NavLink to="/status" className="item">
                <i className="tasks icon"/>
                <span>Управление статусами</span>
            </NavLink>
            <button className="ui red button g__logout" onClick={() => logout()}>
                <i className="logout icon"/>
                <span>Выйти</span>
            </button>
        </div>
    )
}