import { configureStore } from "@reduxjs/toolkit";
import modalSlice from "./reducers/modalReducer";

const store = configureStore({
    reducer: {
        modal: modalSlice
    },
});

export default store;
